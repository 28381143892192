import styled from 'styled-components';

export const ItalicParagraph = styled.p`
  font-style: italic;
`;

export const AboutParagraph = styled.p`
  line-height: 23px;
  font-size: 16px !important;
  margin-bottom: 18px;
`;
