import React, { Component } from 'react';
import loadable from '@loadable/component';
import styled from 'styled-components';

import { AboutParagraph } from '../components/sub-components/infoPageStyledComponents';
const InfoPage = loadable(() => import('../components/InfoPage'));
const ContentBox = loadable(() =>
  import('../components/sub-components/contentBox')
);
import { imageBase } from '../constants';

const AboutImage = styled.img`
  width: 100vw;
  display: none;
  @media only screen and (max-width: 749px) {
    display: block;
  }
`;

class About extends Component {
  render() {
    return (
      <InfoPage
        title="Jennifer Mone Hill Artist - About"
        description="Working primarily with acrylic paint, artist Jennifer Moné Hill creates abstract works exploring viscosity, motion, vibration, and the interaction of color, to provoke emotion, spark curiosity, and visual delight. 
        Her signature cell details and drips down the canvas sides are evidence of the movement and spontaneity of her process.  Jen comes to painting with great enthusiasm, curiosity, intuition, love of color, nature, and positive energy."
        keywords={[
          'about',
          'Jennifer Mone Hill',
          'Jennifer Moné Hill',
          'Jen Hill',
          'Jen Hill Artist',
          'painter',
          'artist',
          'photographer',
          'Connecticut',
          'New York',
          'Danbury',
          'CT',
          'NY',
          'NYC',
          'creative talent',
        ]}
        imageLink="/"
        imgSrc="Jennnifer-Mone-Hill-Artist-with-Spectrum-800.jpg"
        imgSrc2x="Jennnifer-Mone-Hill-Artist-with-Spectrum-1600.jpg"
        alt="Jennifer Mone Hill artist with her painting Spectrum"
        imageMarginTop={70}
      >
        <AboutImage
          src={`${imageBase}Jennnifer-Mone-Hill-Artist-with-Spectrum-320.jpg`}
          alt="Jennifer Mone Hill artist with her painting Spectrum"
          srcSet={`${imageBase}Jennnifer-Mone-Hill-Artist-with-Spectrum-320.jpg 320w,
          ${imageBase}Jennnifer-Mone-Hill-Artist-with-Spectrum-375.jpg 375w,
          ${imageBase}Jennnifer-Mone-Hill-Artist-with-Spectrum-800.jpg 800w,
          ${imageBase}Jennnifer-Mone-Hill-Artist-with-Spectrum-1200.jpg 1200w,
          ${imageBase}Jennnifer-Mone-Hill-Artist-with-Spectrum-1600.jpg 1600w`}
        />
        <ContentBox>
          <h2>About Jennifer</h2>
          <AboutParagraph>
            Abstract artist Jennifer Moné Hill works primarily with acrylic
            paint and resin. Her work explores viscosity, motion, vibration, and
            the interaction of color, to provoke emotion, spark curiosity, and
            visual delight. Her bold color palette, signature cell details,
            wisps, and drips down the canvas sides are evidence of the great
            movement and spontaneity of her process. Jennifer&apos;s art is the
            fusion of controlling the uncontrollable fluidity, using old and
            creating new techniques, pushing materials to the limit,
            experimental ideas, exploring emotion via motion, and the
            interaction of color, while allowing muse to contribute.
            Jennifer&apos;s painting inspiration comes with great enthusiasm,
            curiosity, intuition, love of color, nature, and positive energy.
            <br />
            <br />
            In 2020, Jennifer became a member of the historic Silvermine Guild
            of Artists. You may have seen Jennifer&apos;s work on exhibit on the
            monumental screens at the Oculus World Trade Center, her solo show
            on LinkNYC across from Lincoln Center, at Viridian Gallery in
            Chelsea, and closer to home in recent exhibitions at the Silvermine
            Arts Center, Mattatuck Museum, Hammond Museum, Rene Soto Gallery,
            Rowayton Arts Center, Carriage Barn Arts Center, Ridgefield Guild of
            Artists, and Greenwich Art Society.
            <br />
            <br />
            Jennifer lives and works in Danbury, Connecticut. Born and raised in
            White Plains, New York, she studied Visual Arts at SUNY Purchase,
            and then began working as a commercial photographer for over twenty
            years. Jennifer&apos;s curiosity has led her to simultaneously
            pursue many creative outlets including furniture making, hand dying
            fiber, and of course painting which is now her greatest passion.
            Continuously learning technical processes, inventing new ways, and
            using old and new to conceive something beautiful is the common
            thread in all of her pursuits.
            <br />
            <br />
            &quot;Working in abstract painting, going beyond the
            representational world, to the pure, raw, power of emotion is what
            excites me. When colors combine, when we make a mark that means
            something to the spirit, beyond the known familiar image, breaking
            past the facade of our perception to something greater, that’s what
            motivates me.&quot;
          </AboutParagraph>
        </ContentBox>
      </InfoPage>
    );
  }
}

export default About;
